export default [
  {
    path: "/",
    redirect: "dashboard",
    meta: {
      authAdmin: true,
    },
    component: () => import("@/layouts/AdminLayout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/admin/Dashboard.vue"),
      },
      {
        path: "/client-megalos",
        name: "client megalos",
        component: () => import("@/views/admin/DataClientMegalos.vue"),
      },
      {
        path: "/client-vlepo",
        name: "client vlepo",
        component: () => import("@/views/admin/DataClient.vue"),
      },
      {
        path: "/services",
        name: "services",
        component: () => import("@/views/admin/DataService.vue"),
      },
      {
        path: "/logs",
        name: "logs",
        component: () => import("@/views/admin/Logs.vue"),
      },
      {
        path: "/profile",
        name: "profile",
        component: () => import("@/views/admin/Profile.vue"),
      },
    ],
  },
  {
    path: "/sign-in",
    name: "login",
    meta: {
      guestPage: true,
    },
    component: () => import("@/views/auth/LoginPage.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    meta: {
      authAdmin: true,
    },
    component: () => import("@/layouts/Blank.vue"),
    children: [
      {
        path: "/:pathMatch(.*)*",
        name: "404",
        component: () => import("@/views/error/ErrorNotFound.vue"),
      },
    ],
  },
];
