<template>
  <v-card class="rounded-lg">
    <v-footer class="footer text-bottom rounded-lg">
      <div class="text-right text-bottom w-100">
        copyright {{ new Date().getFullYear() }} —
        <strong>Management Fortex</strong>
      </div>
    </v-footer>
  </v-card>
</template>

<style>
.footer {
  margin-top: auto;
  background-color: #eeeeee;
}
</style>
