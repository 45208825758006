<template>
  <v-navigation-drawer v-model="drawer" app :width="300">
    <v-img src="/images/bg.jpeg" class="pa-0" :width="300" cover height="150">
      <div class="pt-5 pl-4 text-white">
        <v-avatar size="60">
          <v-img src="/icons/icon_megalos_blue.png"></v-img>
        </v-avatar>
        <div class="font-weight-bold text-grey-2">Admin</div>

        <div class="text-white text-capitalize" style="font-size: 13px">
          {{ dataUser.iss }}
        </div>
      </div>
    </v-img>

    <v-divider />

    <v-list density="compact" nav>
      <v-list-item
        color="blue"
        title="Dashboard"
        value="dashboard"
        :to="{ name: 'dashboard' }"
      >
        <template v-slot:prepend>
          <v-icon>mdi-view-dashboard-outline</v-icon>
        </template>
      </v-list-item>

      <v-list-subheader style="font-size: 14px" class="text-primary mt-3">
        PAGES
      </v-list-subheader>

      <v-list-item
        color="blue"
        title="Data Client Megalos"
        value="client-megalos"
        :to="{ name: 'client megalos' }"
      >
        <template v-slot:prepend>
          <v-icon>mdi-account-multiple-outline</v-icon>
        </template>
      </v-list-item>

      <v-list-item
        color="blue"
        title="Data Client Vlepo"
        value="client-vlepo"
        :to="{ name: 'client vlepo' }"
      >
        <template v-slot:prepend>
          <v-icon>mdi-account-multiple-outline</v-icon>
        </template>
      </v-list-item>

      <v-list-item
        color="blue"
        title="Data Services"
        value="services"
        :to="{ name: 'services' }"
      >
        <template v-slot:prepend>
          <v-icon>mdi-database</v-icon>
        </template>
      </v-list-item>

      <v-list-subheader style="font-size: 14px" class="text-primary mt-3">
        EXTRAS
      </v-list-subheader>

      <v-list-item
        color="blue"
        title="Logs"
        value="logs"
        :to="{ name: 'logs' }"
      >
        <template v-slot:prepend>
          <v-icon>mdi-lan</v-icon>
        </template>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  data() {
    return {
      drawer: true,
      dataUser: JSON.parse(localStorage.getItem("dataUser")),
    };
  },
  methods: {
    logout() {
      this.$router.push({ name: "login" });
    },
  },
};
</script>
